import React, { useEffect, useRef, useState } from "react";
import { IOption } from "../../types";
import { BurgerMenu, Cancel } from "../Icons";
import Modal from "./Modal";
import Button from "./Button";
import Input from "./Input";
import { changePassword } from "../../services";

interface ICurtainMenu {
   options: IOption[];
   onSelect: (opt: IOption) => void;
   burgerMenuIcon: boolean;
}

const CurtainMenu: React.FC<ICurtainMenu> = ({ options, onSelect, burgerMenuIcon }) => {
   const [isOpen, setIsOpen] = useState<boolean>(false);
   const [isChangePassword, setIsChangePassword] = useState<boolean>(false);
   const [isLogOut, setIsLogOut] = useState<boolean>(false);
   const [selectedOption, setSelectedOption] = useState<IOption | null>();
   const [oldPwd, setOldPwd] = useState<{value: string, error: boolean}>({value: "", error: false});
   const [newPwd, setNewPwd] = useState<{value: string, error: boolean}>({value: "", error: false});
   const [checkPwd, setCheckPwd] = useState<{value: string, error: boolean}>({value: "", error: false});
   const [specialError, setSpecialError] = useState<{oldPwdIncorrect: boolean, pwdMismatch: boolean}>({oldPwdIncorrect: false, pwdMismatch: false});
   const dropdownRef = useRef<any>(null);

   const handleOptionClick = (option: IOption) => {
      setSelectedOption(option);
      setIsOpen(false);
      onSelect(option);
   };

   const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
         setIsOpen(false);
      }
   };

   useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, []);

   const resetAllStates = () => {
      setOldPwd({value: "", error: false});
      setNewPwd({value: "", error: false});
      setCheckPwd({value: "", error: false});
      setSpecialError({oldPwdIncorrect: false, pwdMismatch: false});
      setIsChangePassword(false);
   }

   const validationFields = () => {
      let hasError = false;
      if (!oldPwd.value) {
         setOldPwd((prevState) => ({...prevState, error: true}));
         hasError = true;
      } else {
         setOldPwd((prevState) => ({...prevState, error: false}));
      }

      if (!newPwd.value) {
         setNewPwd((prevState) => ({...prevState, error: true}));
         hasError = true;
      } else {
         setNewPwd((prevState) => ({...prevState, error: false}));
      }

      if (!checkPwd.value) {
         setCheckPwd((prevState) => ({...prevState, error: true}));
         hasError = true;
      } else {
         setCheckPwd((prevState) => ({...prevState, error: false}));
      }

      if (newPwd.value && checkPwd.value && newPwd.value !== checkPwd.value) {
         setSpecialError((prevState) => ({...prevState, pwdMismatch: true}));
         setNewPwd((prevState) => ({...prevState, error: true}));
         setCheckPwd((prevState) => ({...prevState, error: true}));
         hasError = true;
      } else {
         setSpecialError((prevState) => ({...prevState, pwdMismatch: false}));
      }

      return hasError;
   }

   const handleSave = async() => {
      const validation = validationFields();

      if (validation) {
         return
      } else {
         const response = await changePassword(oldPwd.value, newPwd.value, JSON.parse(localStorage.getItem("authData") as string).auth_token);
         if (!response.isError) {
            resetAllStates();
         } else {
            if (response.isError && response.error === "error400") {
               setSpecialError(prevState => ({ ...prevState, oldPwdIncorrect: true }));
            } else {
               resetAllStates();
            }
         }
      }
   }

   return (
      <>
         <div className="curtain">
            <div className="curtain-burger" onClick={() => setIsOpen(true)}>
               {burgerMenuIcon ? <BurgerMenu /> : null}
            </div>

            <div className={`curtain-list ${isOpen ? 'open' : 'closed'}`} ref={dropdownRef}>
               <ul>
                  {options.map((option: IOption) => (
                     <li
                        key={option.value}
                        className="dropdown-item curtain-list-item"
                        onClick={() => handleOptionClick(option)}
                     >
                        {option.label}
                     </li>
                  ))}
               </ul>

               <ul>
                  {/* <li className="dropdown-item curtain-list-item">Профиль</li> */}
                  <li className="dropdown-item curtain-list-item" onClick={() => setIsChangePassword(true)}>Сменить пароль</li>
                  <li className="dropdown-item curtain-list-item2" onClick={() => setIsLogOut(true)}>Выйти</li>
               </ul>
            </div>
         </div>

         <Modal isOpen={isChangePassword} isSmallModal>
            <div className="delete-modal">
               <div
                  className="delete-modal-close-icon"
                  onClick={resetAllStates}
               >
                  <Cancel color="#7A7A7A" />
               </div>
               <div className="delete-modal-content">
                  <p className="delete-modal-content-title">
                     Создайте новый пароль:
                  </p>

                  <Input
                     label="Введите старый пароль"
                     value={oldPwd.value} 
                     placeholder="Пароль" 
                     type="password"
                     onChange={(e) => setOldPwd({value: e, error: false})} 
                     errorText={specialError.oldPwdIncorrect ? "*Старый пароль введен не верно" : (oldPwd.error ? "*Обязательное поле" : "")}
                     error={oldPwd.error || specialError.oldPwdIncorrect}
                  />

                  <Input
                  label="Введите новый пароль"
                     value={newPwd.value} 
                     placeholder="Пароль"
                     type="password"
                     onChange={(e) => setNewPwd({value: e, error: false})} 
                     errorText={specialError.pwdMismatch ? "" : (newPwd.error ? "*Обязательное поле" : "")}
                     error={newPwd.error || specialError.pwdMismatch}
                  />

                  <Input
                  label="Введите новый пароль еще раз"
                     value={checkPwd.value} 
                     placeholder="Пароль"
                     type="password"
                     onChange={(e) => setCheckPwd({value: e, error: false})} 
                     errorText={specialError.pwdMismatch ? "*Пароли не совпадают" : (checkPwd.error ? "*Обязательное поле" : "")}
                     error={checkPwd.error || specialError.pwdMismatch}
                  />
               </div>
               <div className="delete-modal-content-btns">
                     <Button
                        text="Выйти"
                        onClick={resetAllStates}
                        big
                     />
                     <Button text="Сохранить" onClick={handleSave} blueColor big />
               </div>
            </div>
         </Modal>

         <Modal isOpen={isLogOut} isSmallModal>
            <div className="delete-modal">
               <div
                  className="delete-modal-close-icon"
                  onClick={() => setIsLogOut(false)}
               >
                  <Cancel color="#7A7A7A" />
               </div>
               <div className="delete-modal-content">
                  <p className="delete-modal-content-title">
                     Вы уверены, что хотите выйти из аккаунта?
                  </p>
               </div>
               <div className="delete-modal-content-btns">
                  <Button
                     text="НЕТ"
                     onClick={() => setIsLogOut(false)}
                     big
                  />
                  <Button text="ДА" onClick={() => {localStorage.removeItem("authData"); setIsLogOut(false); window.location.reload()}} blueColor big />
               </div>
            </div>
         </Modal>
      </>
   );
};

export default CurtainMenu;
