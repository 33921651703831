import React, {useEffect, useRef, useState} from 'react';
import {Cancel, Delete, Edit} from "../Icons";
import Modal from "../UI/Modal";
import Button from "../UI/Button";
import Dropdown from '../UI/Dropdown';
import TextArea from '../UI/TextArea';
import { IOption, IParsingEvent, IParsingEventUpdate, IStatuses } from '../../types';
import dayjs from 'dayjs';
import { isAdmin,  isEditor } from '../../data/role';
import { updateParsingEvent, getStatuses, deleteParsingEvent } from '../../services';

interface ICalendarCard {
    event: IParsingEvent;
    isPast: boolean;
    statuses: IOption[];
    onUpdate: () => void;
}

const CalendarCardWithStatus: React.FC<ICalendarCard> = ({isPast, event, statuses, onUpdate}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [status, setStatus] = useState<string | number>(event.status? event.status : "");
    const [isTitleEdit, setTitleEdit] = useState<boolean>(false);
    const [title, setTitle] = useState<string>(event.name);
    const popUpRef = useRef<HTMLDivElement>(null);
    const cardRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isOpen && cardRef.current && popUpRef.current) {
            const cardRect = cardRef.current.getBoundingClientRect();
            const popUp = popUpRef.current;

            if (window.innerWidth - cardRect.right - 30 > popUp.getBoundingClientRect().width) {
                popUp.style.left = `${cardRect.width + window.scrollX + 8}px`;
            }
            else {
                popUp.style.right = `${cardRect.width - window.scrollX + 8}px`;
            }
        }
    }, [isOpen]);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (popUpRef.current && !popUpRef.current.contains(event.target) && cardRef.current && !cardRef.current.contains(event.target)) {
                setIsOpen(false);
                setTitleEdit(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [popUpRef, cardRef]);

    const handleToggle = () => {
        setIsOpen(!isOpen);
        setTitleEdit(false);
    };

    const updateEventName = async (value: string) => {
        setTitle(value);
        updateCard({name: value});
    };

    const updateStatus = async (newStatus: string) => {
        setStatus(newStatus);
        updateCard({status: newStatus});
    };

    const updateCard = async (updateValue: IParsingEventUpdate) => {
        if (event.id) {
            let response = await updateParsingEvent(updateValue, event.id);
            if (!response.isError) {
                onUpdate();
            } else {
            console.log(response.error);
            }
        }
    };

    const handleDelete = async () => {
        if (event.id) {
            let response = await deleteParsingEvent(event.id);
            if (!response.isError) {
                setIsOpen(false);
                onUpdate();
            }
            else {
                console.log(response.error);
            }
        }
    };

    return (
        <div className="calendar-card-container">
            <div
                ref={cardRef}
                className={`calendar-card calendar-card-with-status ${isOpen ? 'calendar-card-active' : ''}
                            ${isPast ? 'calendar-card-past' : ''}
                            ${status !== "todo" ? status : ""}`}
                onClick={handleToggle}>
                <div className="calendar-card-status-name">
                    {(statuses.find(el => el.value === status))?.label}
                </div>
                <p className="calendar-card-title">
                    {event.name}
                </p>
            </div>
            {isOpen && (
                <div className="pop-up" ref={popUpRef}>
                    <div className="pop-up-icons">
                        {isAdmin() &&
                            <div onClick={() => setIsOpenModal(!isOpenModal)}><Delete /></div>
                        }
                        <div onClick={handleToggle}><Cancel /></div>
                    </div>
                    <div className="pop-up-content">
                        <div className="pop-up-content-input-container">
                            {(isAdmin() || isEditor()) &&
                            <p
                                className={`change-title ${isTitleEdit ? "active" : ""}`}
                                onClick={() => setTitleEdit(!isTitleEdit)}>
                                Изменить заголовок
                            </p>
                            }
                            <TextArea
                                isActive={isTitleEdit}
                                value={title}
                                onSave={(value: string) => updateEventName(value)}
                                onCloseEditMode={() => {setTitleEdit(!isTitleEdit)}}
                            />
                        </div>
                        <div className="pop-up-content-data">
                            <div className="pop-up-content-dropdown">
                                <p className="pop-up-content-dropdown-title">Статус</p>
                                <Dropdown
                                    options={statuses}
                                    onSelect={(option) => {updateStatus(option.value.toString())}}
                                    selectedOpt={statuses.find(el => el.value === status)}
                                    withoutBorder={true}
                                    disabled={!(isAdmin() || isEditor())}
                                />
                            </div>
                            <div className="pop-up-content-data-block">
                                <span className="pop-up-content-data-title-bold">Дата проведения:</span>
                                <span className="pop-up-content-data-title">{dayjs(event.date).format("DD.MM.YYYY")}</span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <Modal isOpen={isOpenModal} isSmallModal>
                <div className="delete-modal">
                    <div className="delete-modal-close-icon" onClick={() => setIsOpenModal(!isOpenModal)}>
                        <Cancel color="#7A7A7A" />
                    </div>
                    <div className="delete-modal-content">
                        <p className="delete-modal-content-title">Вы уверены что хотите <br/> удалить мероприятие:</p>
                        <p className="delete-modal-content-subtitle">
                            {event.name}
                        </p>
                    </div>
                    <div className="delete-modal-content-btns">
                        <Button text="Удалить" onClick={() => handleDelete()} redColor big />
                        <Button text="Отмена" onClick={() => setIsOpenModal(!isOpenModal)} big />
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default CalendarCardWithStatus;