const currentYear = ((new Date()).getFullYear()).toString();
const newxYear = ((new Date()).getFullYear() + 1).toString();

export const burgerMenuList = [
  {
    label: "Календарь медиа",
    value: "",
  },
  {
    label: "Статистика",
    value: "statistics",
  },
  {
    label: "Аналитика",
    value: "analytics",
  },
  {
    label: "СМИ",
    value: "media",
  },
  {
    label: "Пользователи",
    value: "users",
  }
];

export const annualCalendarList = [
  {
    label: "Неделя",
    value: "week",
  },
  {
    label: "Месяц",
    value: "month",
  },
  {
    label: "Год",
    value: "year",
  },
];

export const statisticYears = [
  {
    label: currentYear,
    value: currentYear,
  },
  {
    label: newxYear,
    value: newxYear,
  },
];

export const monthsOptions = [
  {
    value: 1,
    label: "январь",
  },
  {
    value: 2,
    label: "февраль",
  },
  {
    value: 3,
    label: "март",
  },
  {
    value: 4,
    label: "апрель",
  },
  {
    value: 5,
    label: "май",
  },
  {
    value: 6,
    label: "июнь",
  },
  {
    value: 7,
    label: "июль",
  },
  {
    value: 8,
    label: "август",
  },
  {
    value: 9,
    label: "сентябрь",
  },
  {
    value: 10,
    label: "октябрь",
  },
  {
    value: 11,
    label: "ноябрь",
  },
  {
    value: 12,
    label: "декабрь",
  }
];