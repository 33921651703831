import React, { useEffect, useRef, useState } from "react";
import { Cancel, Delete, Edit, GrayCircle, GreenCircle } from "../Icons";
import Checkbox from "../UI/Checkbox";
import Modal from "../UI/Modal";
import Button from "../UI/Button";
import { IEvent, IEventPublication, IEventUpdate } from "../../types/events";
import { IOrganizations } from "../../types";
import { deleteEvent, updateEvent } from "../../services";
import { getUserRole } from '../../data/role';
import { isAdmin, isEditor } from "../../data/role";

interface ICalendarCard {
    mediaEvent: IEvent;
    isPast: boolean;
    organization: IOrganizations | undefined;
    onUpdate: () => void;
    openEditModal: ( event: IEvent | null) => void;
}

const CalendarCard: React.FC<ICalendarCard> = ({
    mediaEvent,
    isPast,
    organization,
    onUpdate,
    openEditModal,
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const popUpRef = useRef<HTMLDivElement>(null);
    const cardRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isOpen && cardRef.current && popUpRef.current) {
            const cardRect = cardRef.current.getBoundingClientRect();
            const popUp = popUpRef.current;

            if (window.innerWidth - cardRect.right - 30 > popUp.getBoundingClientRect().width) {
                popUp.style.left = `${cardRect.width + window.scrollX + 8}px`;
            } else {
                popUp.style.right = `${cardRect.width - window.scrollX + 8}px`;
            }
        }
    }, [isOpen]);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (popUpRef.current && !popUpRef.current.contains(event.target) && cardRef.current && !cardRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [popUpRef, cardRef]);

    const handleToggle = () => setIsOpen(!isOpen);

    const getFormattedDate = (date: string) => {
        let dateArray = date.split("-");
        return `${dateArray[2]}.${dateArray[1]}.${dateArray[0]}`;
    };

    const updateCard = async (data: IEventUpdate) => {
        const response = await updateEvent(data, mediaEvent.id);
        if (!response.isError) {
            onUpdate();
        } else {
        console.log(response.error);
        }
    };

    const changePublicationStatus = (status: string, publication: IEventPublication) => {
        if (isAdmin() || isEditor()) {
            let updateParams: IEventUpdate = {publications: [{ id: publication.id, status: status }],};
            updateCard(updateParams);
        }
    };

    const handleDeleteEvent = async () => {
        const response = await deleteEvent(mediaEvent.id);
        if (!response.isError) {
            onUpdate();
            setIsOpenModal(false);
        } else {
            console.error(response.error);
        }
    };

    const getPublicationStatus = () => {
        let done = 0;
        let notDone = 0;

        mediaEvent.publications.forEach((publication) => {
            if (publication.status === "not_done") {
                notDone++;
            } else {
                done++;
            }
        });

        if (done && notDone) {
            return <><GreenCircle /> <GrayCircle /></>
        } else if (notDone && !done) {
            return <GrayCircle />
        } else if (!notDone && done) {
            return <GreenCircle />
        } else {
            return null;
        }
    };

    return (
        <div className="calendar-card-container">
            <div
                ref={cardRef}
                className={`calendar-card ${isOpen ? "calendar-card-active" : ""} ${isPast ? "calendar-card-past" : ""}`}
                onClick={handleToggle}
            >
                <div className="calendar-card-status">
                    {getPublicationStatus()}
                </div>
                <p className="calendar-card-title">{mediaEvent.name}</p>
                <p className="calendar-card-sub-title">
                    Отв. {organization ? organization.name : ""}
                </p>
            </div>

            {isOpen && (
                <div className="pop-up" ref={popUpRef}>
                    <div className="pop-up-icons">
                        {isAdmin() &&
                            <div onClick={() => setIsOpenModal(!isOpenModal)}>
                                <Delete />
                            </div>
                        }
                        { ( isAdmin() || isEditor() ) &&
                        <div onClick={() => openEditModal(mediaEvent)}>
                            <Edit />
                        </div>
                        }
                        <div onClick={handleToggle}>
                            <Cancel />
                        </div>
                    </div>
                    <div className="pop-up-content">
                        <div className="pop-up-content-title">{mediaEvent.name}</div>
                        <div className="pop-up-content-publisher">
                            <p>Публикации</p>
                            <div className="pop-up-content-publisher-checkbox">
                                {mediaEvent.publications.map((publication) => (
                                    <Checkbox
                                        label={publication.media_name}
                                        isChecked={publication.status === "not_done" ? false : true}
                                        url={publication.url}
                                        key={publication.id}
                                        isDisabled={!(isAdmin() || isEditor())}
                                        onChange={(status: string) =>
                                            changePublicationStatus(status, publication)
                                        }
                                    />
                                ))}
                            </div>
                        </div>
                        <div className="pop-up-content-data">
                            <div className="pop-up-content-data-block">
                                <span className="pop-up-content-data-title-bold">Дата проведения:</span>
                                <span className="pop-up-content-data-title">
                                    {getFormattedDate(mediaEvent.date)}
                                </span>
                            </div>
                            <div className="pop-up-content-data-block">
                                <span className="pop-up-content-data-title-bold">Ответственный:</span>
                                <span className="pop-up-content-data-title">{organization ? organization.name : ""}</span>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <Modal isOpen={isOpenModal} isSmallModal>
                <div className="delete-modal">
                    <div
                        className="delete-modal-close-icon"
                        onClick={() => setIsOpenModal(!isOpenModal)}
                    >
                        <Cancel color="#7A7A7A" />
                    </div>
                    <div className="delete-modal-content">
                        <p className="delete-modal-content-title">
                            Вы уверены что хотите <br /> удалить мероприятие:
                        </p>
                        <p className="delete-modal-content-subtitle">{mediaEvent.name}</p>
                    </div>
                    <div className="delete-modal-content-btns">
                        <Button text="Удалить" onClick={handleDeleteEvent} redColor big />
                        <Button
                            text="Отмена"
                            onClick={() => setIsOpenModal(!isOpenModal)}
                            big
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default CalendarCard;
