import React, { useEffect, useState } from "react";
import BarChart from "../Charts/BarChart";
import LineChart from "../Charts/LineChart";
import StatisticTotalTable from "./StatisticTotalTable";
import { getStatisticData, getStatisticMediaData } from "../../services";

interface IStatistic {
    type: string;
    selectedYear: string;
}

const StatisticChart: React.FC<IStatistic> = ({ type, selectedYear }) => {
    const [chartData, setChartData] = useState<any>(null);
    useEffect(() => {
        loadData();
    }, [selectedYear]);

    const getLabels = (type: string) => {
        let labels;
        switch (type) {
            case "sources":
                labels = ["Печатные СМИ", "Телевизионные СМИ", "Социальные сети"];
                break;
            case "year":
                labels = [selectedYear];
                break;
            case "semester":
                labels = ["Первое полугодие", "Второе полугодие"];
                break;
            case "quarter":
                labels = ["1 квартал", "2 квартал", "3 квартал", "4 квартал"];
                break;
            case "month":
                labels = ["Янв", "Фев", "Март", "Апр", "Май", "Июнь", "Июль", "Авг", "Сен", "Окт", "Нояб", "Дек"];
                break;
            case "week":
                labels = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];
                break;
        }
        return labels;
    };

    const loadData = async () => {
        let result: any;
        if (type === "sources") {
            result = await getStatisticMediaData("year", selectedYear);
        }
        else {
            result = await getStatisticData(type, selectedYear);
        }
        if (result && !result.isError) {
            setChartData({
                labels: type !== "sources" ? getLabels(type) : result.data.map((el : any) => el.source ? el.source.replace("/", "/ ") : "Другие").map((el: any) => el.split(" ")),
                datasets: [
                    {
                        label: "Исполненные",
                        data: Array.isArray(result.data)
                            ? result.data.map((el : any) => el.done_count)
                            : [result.data.done_count],
                        borderColor: "#79BD8F",
                        backgroundColor: "#79BD8F",
                    },
                    {
                        label: "Неисполненные",
                        data: Array.isArray(result.data)
                            ? result.data.map((el : any) => el.not_done_count)
                            : [result.data.not_done_count],
                        borderColor: "#FF6138",
                        backgroundColor: "#FF6138",
                    },
                ],
            });
        }
    };

    return (
        <div className={`chart-box ${type}`}>
            {chartData && (
                <>
                    <div className="chart" style={{ height: "300px" }}>
                        {type === "week" ? (
                            <LineChart
                                data={chartData}
                                chartHeight={300}
                                chartWidth={200}
                                isLegend={false}
                            />
                        ) : (
                            <BarChart
                                data={chartData}
                                chartHeight={300}
                                chartWidth={200}
                                isLegend={false}
                            />
                        )}
                    </div>
                    <StatisticTotalTable type={type} chartData={chartData} />
                </>
            )}
        </div>
    );
};

export default StatisticChart;
