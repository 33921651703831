import React from 'react';

interface IStatisticTotalTable {
    type: string;
    chartData: any;
}

const StatisticTotalTable: React.FC<IStatisticTotalTable> = ( {type, chartData} ) => {
    const getTypeOfReport = (type: string) => {
        if (type === "year") return {name: "Год", adjective: "Год"};
        if (type === "semester") return {name: "Полугодие", adjective: "Полугодие"};
        if (type === "quarter") return {name: "Квартал", adjective: "Квартальный"};
        if (type === "month") return {name: "Месяц", adjective: "Месячный"};
        if (type === "week") return {name: "День", adjective: "Недельный"};
        return {name: "Тип СМИ", adjective: "по источникам за год"};
    }
    const getTotalValue = () => {
        return chartData.datasets[0].data.map((el: any, index: number) => el + chartData.datasets[1].data[index]);
    }

    return (
        <div className="statistic-total-data">
            <p className="statistic-total-data-name">ОТЧЕТ {getTypeOfReport(type).adjective.toUpperCase()}</p>
            <table className="statistic-total-data-table">
                {type !== "year" &&
                    <thead>
                        <tr>
                            <th className="name">{getTypeOfReport(type).name}</th>
                            {chartData.labels.map((el: any, index: number) =>
                            <th key={index}>{Array.isArray(el) ? el.join(" ") : el}</th>
                            )}
                        </tr>
                    </thead>
                }
                <tbody>
                    {chartData.datasets.map((el: any, index: number) =>
                    <tr key={index}>
                        <td className="name">{el.label}</td>
                        {el.data.map((data: any, index: number) =>
                            <td className={`${type === "year" ? "year-td" : ""}`} key={index}>{data}</td>
                        )}
                    </tr>
                    )}
                </tbody>
                <tfoot>
                    <tr>
                        <td className="name">ВСЕГО</td>
                        {getTotalValue().map((el: any, index: number) =>
                        <td className={`${type === "year" ? "year-td" : ""}`} key={index}>{el}</td>
                        )}
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default StatisticTotalTable;