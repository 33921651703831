import React, { useEffect, useState, useContext } from "react";
import StatisticChart from "./StatisticChart";
import { getStatisticData, getDocxReport } from "../../services";
import StatisticEmpty from "./StatisticEmpty";
import { DateContext } from "../../contexts/DateContext";
import Title from "../Title";
import { Cancel, WordIcon } from "../Icons";
import Modal from "../UI/Modal";
import Dropdown from "../UI/Dropdown";
import { monthsOptions, statisticYears } from "../../data/dropdownValues";
import { IOption } from "../../types";
import Button from "../UI/Button";

const StatiscticPage = () => {
    Title("Статистика");
    const [isOpenExportModal, setIsOpenExportModal] = useState<boolean>(false);
    const [notEmpty, setNotEmpty] = useState<boolean | null>(null);
    const [year, setYear] = useState<IOption>(statisticYears[0]);
    const [month, setMonth] = useState<IOption>(monthsOptions[0]);
    const { selectedYear } = useContext(DateContext);

    useEffect(() => {
        setNotEmpty(null);
        loadYearStatisticData(selectedYear);
    }, [selectedYear]);

    const loadYearStatisticData = async (year: string) => {
        let result = await getStatisticData("year", year);
        setNotEmpty(true);
        if (!result.isError && !Array.isArray(result.data)) {
            setNotEmpty(result.data.total_count !== 0);
        }
    };

    const downloadFile = async () => {
        const token = localStorage.getItem("authData") ? JSON.parse(localStorage.getItem("authData") as string).auth_token : null;
        if (token) {
            const response = await getDocxReport(token, Number(year.value), Number(month.value));
            if (!response.isError) {
                const fileUrl = window.URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = fileUrl;
                link.setAttribute('download', 'report.docx');
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link);
            } else {
                console.error('Произошла ошибка при скачивании файла:', response.error);
            }
            setIsOpenExportModal(false);
        }
    };

    let periods = ["sources", "year", "semester", "quarter", "month", "week"];
    return (
        <>
            <div className="statistic">
                <div className="statistic-header">
                    <h1>Статистика</h1>
                    <div className="statistic-header-status">
                        <div className="completed-mark"></div>
                        <p>Исполненные</p>
                    </div>
                    <div className="statistic-header-status">
                        <div className="not-completed-mark"></div>
                        <p>Неисполненные</p>
                    </div>
                    <button className="export-button" onClick={() => setIsOpenExportModal(true)}>
                            <p>экспорт</p>
                            <WordIcon/>
                    </button>
                </div>
                <div className="statistic-grid-container">
                    {notEmpty
                    ?
                        periods.map((el: string) => (
                            <StatisticChart
                                type={el}
                                selectedYear={selectedYear}
                                key={el}
                            />
                        ))
                    :
                    notEmpty !== null ? <StatisticEmpty/> : <></>
                    }
                </div>
            </div>
            <Modal isOpen={isOpenExportModal} isSmallModal>
                <div className="export-modal">
                    <div className="export-modal-close-icon" onClick={() => setIsOpenExportModal(false)}>
                        <Cancel/>
                    </div>
                    <div className="export-modal-year">
                        <Dropdown
                            options={statisticYears}
                            selectedOpt={year}
                            onSelect={(option) => setYear(option)}
                            label="Выберите год"
                        />
                    </div>
                    <div className="export-modal-month">
                        <Dropdown
                            options={monthsOptions}
                            selectedOpt={month}
                            onSelect={(option) => setMonth(option)}
                            label="Выберите месяц"
                        />
                    </div>
                    <div className="export-modal-button">
                        <Button text="Экспорт" onClick={downloadFile} blueColor/>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default StatiscticPage;
