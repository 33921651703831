import React, { useState, useEffect } from "react";
import Accordion from "../UI/Accordion";
import { IFilterCategory, IMediaList, IOrganizations } from "../../types";
import { getOrganizations, getFilterCategory } from "../../services";
import Checkbox from "../UI/Checkbox";

interface FilterProps {
    media?: number[];
    organization?: number | null;
    onUpdateFilters: (selectedtedMedia: number[], selectedOrganization: number | null) => void;
}

const CalendarFilter: React.FC<FilterProps> = ({media, organization, onUpdateFilters}) => {
    const [groupedFilters, setGroupedFilters] = useState<
        Record<string, IFilterCategory[]>
    >({});
    const [organizations, setOrganizations] = useState<IOrganizations[]>([]);
    const [selectedMediaCategory, setSelectedMediaCategory] = useState<number[]>([]);
    const [selectedMedia, setSelectedMedia] = useState<number[]>(media? media : []);
    const [selectedOrganization, setSelectedOrganization] = useState<number | null>(organization? organization : null);

    useEffect(() => {
        loadOrganizations();
        loadCategory();
    }, []);

    useEffect(() => {
        onUpdateFilters(selectedMedia, selectedOrganization);
    }, [selectedOrganization, selectedMedia]);

    const loadOrganizations = async () => {
        const response = await getOrganizations();
        if (!response.isError) {
            setOrganizations(response.data);
        } else {
            console.log("error");
        }
    };

    const loadCategory = async () => {
        const response = await getFilterCategory();
        if (!response.isError) {
            let data = response.data;
            let group: Record<string, IFilterCategory[]> = {};
            data.forEach((filter) => {
                if (!group[filter.group]) {
                    group[filter.group] = [];
                }
                group[filter.group].push(filter);
            });
            if (media && media.length !== 0) {
                Object.keys(group).forEach((groupType) => {
                    group[groupType].forEach(filter => {
                        if(filter.media_list.every(media => selectedMedia.includes(media.id))) {
                            if (!selectedMediaCategory.includes(filter.id)) {
                                setSelectedMediaCategory((prev) => [...prev, filter.id]);
                            }
                        }
                    })
                })
            }
            setGroupedFilters(group);
        } else {
            console.log("error");
        }
    };

    const updateAllMedia = (status: string, filter: IFilterCategory, selectedMedia: number[]) => {
        let newSelected = selectedMedia;
        if (status === "done") {
            filter.media_list.forEach((el) => {
                if (!selectedMedia.includes(el.id)) {
                    newSelected = [...newSelected, el.id];
                }
            });
            if (!selectedMediaCategory.includes(filter.id)) {
                setSelectedMediaCategory((prev) => [...prev, filter.id]);
            }
        } else {
            filter.media_list.forEach((el) => {
                newSelected = newSelected.filter((val) => val !== el.id);
            });
            setSelectedMediaCategory((prev) => prev.filter((id) => id !== filter.id));
        }
        return newSelected;
    };

    const checkedAllMedia = (status: string, filter: IFilterCategory) => {
        let newSelected = updateAllMedia(status, filter, selectedMedia);
        setSelectedMedia(newSelected);
    };

    const checkedAllMediaType = (status: string, group: string) => {
        if (group) {
            let newSelected = selectedMedia;
            groupedFilters[group].forEach((filter) => {
                newSelected = updateAllMedia(status, filter, newSelected);
            });
            setSelectedMedia(newSelected);
        }
    };

    const checkedMedia = (status: string, media: IMediaList, filter: IFilterCategory) => {
        if (status === "done") {
            let newSelectedMedia = [...selectedMedia, media.id]
            setSelectedMedia([...newSelectedMedia]);
            if (filter.media_list.every(el => newSelectedMedia.includes(el.id)) && !selectedMediaCategory.includes(filter.id)) {
                setSelectedMediaCategory((prev) => [...prev, filter.id]);
            }
        } else {
            setSelectedMedia((prev) => prev.filter((id) => id !== media.id));
            setSelectedMediaCategory((prev) => prev.filter((id) => id !== filter.id));
        }
    }

    return (
        <div className="calendar-filter">
            <p className="title">Фильтр</p>
            <div className="filter-media">
                {Object.keys(groupedFilters).map((group, index) => (
                    <Accordion
                        title={group}
                        checkedAll={groupedFilters[group].every(el => selectedMediaCategory.includes(el.id))}
                        onCheckedAll={(status: string) => checkedAllMediaType(status, group)}
                        key={index}
                    >
                        {groupedFilters[group].map((filter) => (
                            <Accordion
                                title={filter.name}
                                checkedAll={selectedMediaCategory.includes(filter.id)}
                                onCheckedAll={(status: string) => checkedAllMedia(status, filter)}
                                key={filter.id}
                            >
                                {filter.media_list.map((media) => (
                                    <Checkbox
                                        label={`${media.name} ${media.id}`}
                                        isChecked={selectedMedia.includes(media.id)}
                                        onChange={(status) => {checkedMedia(status, media, filter)}}
                                        labelStyle={{fontSize: "16px", color: "#7A7A7A" }}
                                        key={media.id}
                                    />
                                ))}
                            </Accordion>
                        ))}
                    </Accordion>
                ))}
            </div>
            <div className="filter-organization">
                <Accordion
                    title={"Ответственные ведомства"}
                    checkedAll={false}
                    isWithoutCheckboxTitle={true}
                >
                    {organizations.map((organization) => (
                        <Checkbox
                            label={organization.name}
                            isChecked={selectedOrganization === organization.id}
                            onChange={(status) => {
                                if (status === "done") {
                                    setSelectedOrganization(organization.id);
                                } else {
                                    setSelectedOrganization(null);
                                }
                            }}
                            labelStyle={{fontSize: "16px", color: "#7A7A7A" }}
                            key={organization.id}
                        />
                    ))}
                </Accordion>
            </div>
        </div>
    );
};

export default CalendarFilter;
