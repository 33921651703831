import React, { useContext, useEffect, useRef, useState } from "react";
import {Calendar, Cross, Plus, Filter, Tick, Upload} from "../Icons";
import DatePicker from "../UI/DatePicker";
import { DateContext } from '../../contexts/DateContext';
import HeaderSlider from "../HeaderSlider/HeaderSlider";
import { isAdmin } from '../../data/role';
import { importFile } from "../../services";

import CalendarFilter from "./CalendarFilters";
import Modal from "../UI/Modal";
import { Cancel } from "../Icons";
import Button from "../UI/Button";

interface ISidebar {
    openModal: () => void;
    onSelect: (selectedDate: Date) => void;
    selectedMedia: number[];
    selectedOrganization: number | null;
    onUpdateFilters: (selectedtedMedia: number[], selectedOrganization: number | null) => void;
}

const Sidebar: React.FC<ISidebar> = ({openModal, onSelect, onUpdateFilters, selectedMedia, selectedOrganization}) => {
    const {selectedDate, calendarPeriod} = useContext(DateContext);
    const [isOpenCalendar, setIsOpenCalendar] = useState(false);
    const [isOpenFilters, setIsOpenFilters] = useState(false);
    const [isTablet, setIsTablet] = useState<boolean>(false);
    const [media, setMedia] = useState<number[]>(selectedMedia);
    const [organization, setOrganization] = useState<number | null>(selectedOrganization);
    const datePickerContainer = useRef<HTMLDivElement>(null);
    const [file, setFile] = useState<File>();
    const [message, setMessage] = useState<{success: boolean, error: boolean}>({success: false, error: false});
    const inputFile = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        setMedia(selectedMedia);
    }, [selectedMedia]);

    useEffect(() => {
        setOrganization(organization);
    }, [organization]);

    useEffect(() => {
        handleResize();
        if (visualViewport) {
            visualViewport.addEventListener('resize', () => {
                handleResize();
            });
        }
        return () => {
            if (visualViewport) {
                visualViewport.removeEventListener('resize', handleResize);
            }
        };
    }, []);

    const handleResize = () => {
        setIsTablet(window.innerWidth <= 925);
    };

    const handleOutsideClick = (event: MouseEvent) => {
        if (datePickerContainer.current && !datePickerContainer.current.contains(event.target as Node)) {
            setIsOpenCalendar(false);
        }
    };

    const updateMediaAndOrganization = (updateSelectedMedia: number[], updateSelectedOrganization: number | null) => {
        setMedia(updateSelectedMedia);
        setOrganization(updateSelectedOrganization);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const handleFileUpload = (e: any) => {
        const { files } = e.target;
        if (files && files.length) {
          setFile(files[0]);
        }
    };

    const onButtonClick = () => {
        if (inputFile.current) {
            inputFile.current.value = '';
            inputFile.current.click();
        }
    };

    let windowMessage;
    if (message.success && !message.error) {
        windowMessage =
            <div className="success">
                <Tick />
                <span className="success-text">Загрузка успешно завершена.</span>
            </div>
    }
    if (!message.success && message.error) {
        windowMessage =
            <div className="fail">
                <Cross />
                <div className="fail-text">
                    <span className="fail-text1">Ошибка загрузки.</span>
                    <span className="fail-text2">Неверный формат файла. Загрузите другой.</span>
                </div>
            </div>
    }

    const uploadFile = async () => {
        const result = await importFile(file as File);
        if (!result.isError) {
        setMessage({success: true, error: false});
        } else {
        setMessage({success: false, error: true});
        }
    }

    useEffect(() => {
        if (file) {
            uploadFile();
        }
    }, [file]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setMessage({success: false, error: false});
        }, 5000);
        return () => clearTimeout(timer);
    }, [message]);

    return (
        <>
            <div className="sidebar">
                <div className="sidebar-header">
                    {isAdmin() &&
                    <>
                        <div className={`sidebar-btn ${isTablet ? "sidebar-btn-tablet" : ""}`} onClick={openModal}>
                            <div className="sidebar-btn-plus">
                                <Plus />
                            </div>
                            {!isTablet &&
                            <p>Создать</p>
                            }
                        </div>
                        <div className={`sidebar-btn ${isTablet ? "sidebar-btn-tablet" : ""}`} onClick={onButtonClick}>
                            <div className="sidebar-btn-plus">
                                <Upload />
                            </div>
                            {!isTablet &&
                                <p>Импорт Excel</p>
                            }
                            <input
                                style={{ display: "none" }}
                                ref={inputFile}
                                onChange={handleFileUpload}
                                type="file"
                            />
                        </div>
                    </>
                    }
                    {isTablet
                    &&
                    <>
                        <div className="header-center">
                            <HeaderSlider calendarPeriod={calendarPeriod} />
                        </div>
                        <div className="sidebar-header-actions">
                            <div className="sidebar-header-calendar-container">
                                <div className="sidebar-header-calendar-icon" onClick={() => setIsOpenCalendar(true)}>
                                    <Calendar width={24} height={24}/>
                                </div>
                                {isOpenCalendar &&
                                <div className="sidebar-header-calendar" ref={datePickerContainer}>
                                    <DatePicker
                                        selectedDate={selectedDate}
                                        onSelect={(date) => onSelect(date)}
                                    />
                                </div>
                                }
                            </div>
                            <div className="sidebar-header-filter-icon" onClick={() => setIsOpenFilters(true)}>
                                <Filter width={24} height={24}/>
                            </div>
                        </div>
                    </>
                    }
                </div>
                {!isTablet
                &&
                <>
                    <DatePicker
                        selectedDate={selectedDate}
                        onSelect={(date) => onSelect(date)}
                    />
                    <div className="sidebar-body">
                        <CalendarFilter onUpdateFilters={onUpdateFilters}/>
                    </div>
                </>
                }
                {isOpenFilters &&
                    <Modal isOpen={isOpenFilters} isSmallModal={true}>
                        <div className="delete-modal-close-icon" onClick={() => setIsOpenFilters(false)}>
                            <Cancel color="#7A7A7A" />
                        </div>
                        <div className="calendar-filter-modal">
                            <CalendarFilter
                                onUpdateFilters={updateMediaAndOrganization}
                                media={media}
                                organization={organization}
                            />
                        </div>
                        <div className="delete-modal-content-btns">
                            <Button text="Отмена" onClick={() => setIsOpenFilters(false)} />
                            <Button
                                text="Применить"
                                onClick={() => {onUpdateFilters(media, organization); setIsOpenFilters(false);}}
                                blueColor
                            />
                        </div>
                    </Modal>
                }
            </div>
            {windowMessage}
        </>
    );
};

export default Sidebar;
